import React from 'react';
import PropTypes from 'prop-types';

Login.propTypes = {
    
};

function Login(props) {
    return (
        <div>Login</div>
    );
}

export default Login;