import './App.css';
import AppHeader from "./components/AppHeader";
import {Outlet} from "react-router-dom";

function AppLayout() {
  return (

          <div className="App">
              <AppHeader/>

          </div>


  );
}

export default AppLayout;
