import React from 'react';
import PropTypes from 'prop-types';

About.propTypes = {
    
};

function About(props) {
    return (
        <div>About</div>
    );
}

export default About;