import {Link, Outlet} from "react-router-dom";

let AppHeader = () => <header className="App-header">
    <p>
        DEN<b><i>sdin</i></b>
        <br/>
        <Link to="/">Home</Link>&nbsp;|&nbsp;
        <Link to="/login">Login</Link>&nbsp;|&nbsp;
        <Link to="/about">About</Link>
    </p>
    <Outlet/>

</header>;

export default AppHeader;