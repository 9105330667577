import React from 'react';
import PropTypes from 'prop-types';

const Home = props => {
    return (
        <div>
            Home
        </div>
    );
};

Home.propTypes = {
    
};

export default Home;